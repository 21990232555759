import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Typography, Grid, Box } from "@material-ui/core"

import SEO from "../components/seo"

const AboutPage = () => {
  const {
    file: {
      childMarkdownRemark: { html },
    },
  } = useStaticQuery(graphql`
    query {
      file(name: { eq: "about" }) {
        childMarkdownRemark {
          html
        }
      }
    }
  `)

  return (
    <>
      <SEO title="About Us"></SEO>
      <Box fontWeight="fontWeightBold" textAlign="center" pt={3}>
        <Typography variant="h3" color="primary" gutterBottom>
          About Us
        </Typography>
      </Box>
      <Grid container>
        <Box
          textAlign="center"
          width="100%"
          display="flex"
          justifyContent="center"
        >
          <Grid item md={12}>
            <Typography variant="body1" component="div">
              <p dangerouslySetInnerHTML={{ __html: html }} />
            </Typography>
          </Grid>
        </Box>
      </Grid>
    </>
  )
}

export default AboutPage
